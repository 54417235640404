import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import BackgroundImage from 'gatsby-background-image';

const getImage = graphql`
{
    fluidImage: file(relativePath: {eq: "colorful-sunset-sunrise-dramatic-sky-above-wooden--FLSXCYZ.jpg"}) {
        id
        childImageSharp {
            fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid
            }
        }
      }
    }
`

export default ({ children }) => {
    const { fluidImage: { childImageSharp: { fluid: image } } } = useStaticQuery(getImage);

    const colorDarkChocolate = `119, 79, 56`;
    const colorLightPeach = `224, 141, 121`;

    const imageStack = [
        `linear-gradient(to right bottom, rgba(${colorDarkChocolate}, .3), rgba(${colorLightPeach}, .3))`,
      image,
    ]

  return (
    <>
      <div>
    <BackgroundImage
      Tag="section"
      className={`header`}
      fluid={imageStack}
      
      // backgroundColor={`#040e18`}
    >
        {children}
    </BackgroundImage>
      </div>
    </>
  )
}
