import React from "react"
import { Link } from "gatsby"
import HeroImage from "../components/heroImage"
import Navigation from "../components/Navigation"
import Layout from "../components/Layout"
import "../styles/styles.scss"

export default () => {
  return (
        <HeroImage>
          <div className="header__text-box">
            <h1 className="header--heading-primary">
              <span className="heading-primary-main">Hartley </span>
              <span className="heading-primary-sub">Clinical Psychology</span>
            </h1>
          </div>
        </HeroImage>
  )
}
