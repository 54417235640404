import React from "react";

import Header from "../components/header";
import Layout from "../components/Layout";

const IndexPage = () => (
  <Layout
    hideFooter={true}
  >
    <Header />
    <div className="block-quote--wrapper">
      <div className="block-quote-text">
        <span className="block-quote-text--primary">
          Through human connection and clinical expertise
            </span>
        <span className="block-quote-text--secondary">
          come repair, empowerment and growth
            </span>
      </div>
    </div>

    {/* <SEO title="Home" /> */}

  </Layout>
)

export default IndexPage